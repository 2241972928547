.container {
  width: 100%;
  height: 50vh;
  position: relative;
  font-family: 'Assistant';
  overflow: hidden;
}

.slider {
  height: 100%;
}

.slide {
  height: 50vh;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.mainContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  z-index: 1;
}

.titleWrapper {
  background-color: rgba(214, 19, 17, 0.8);
  padding: 1rem;
  margin-bottom: 2rem;
}

.title {
  font-size: 2.5rem;
  color: white;
  margin: 0;
}

.cta {
  background-color: white;
  color: #D61311;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

/* Responsive styles */
@media (max-width: 350px) {
  .container, .slide {
    height: 40vh;
  }
  .backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .title {
    font-size: 1.4rem;
  }
  .mainContent {
    padding: 0.2rem;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .container, .slide {
    height: 40vh;
  }
  .title {
    font-size: 1.6rem;
  }
  .backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .mainContent {
    padding: 0.4rem;
  }
}

@media (min-width: 450px) and (max-width: 550px) {
  .title {
    font-size: 2rem;
  }
  .backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .mainContent {
    padding: 0.4rem;
  }
}

@media (min-width: 550px) and (max-width: 650px) {
  .title {
    font-size: 2.2rem;
  }
  .backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media (min-width: 650px) and (max-width: 750px) {
  .container, .slide {
    height: 50vh;
  }
  .backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media (min-width: 750px) and (max-width: 850px) {
  .container, .slide {
    height: 45vh;
  }
  .title {
    font-size: 2.3rem;
  }
}

@media (min-width: 850px) and (max-width: 950px) {
  .container, .slide {
    height: 45vh;
  }
}

@media (min-width: 950px) and (max-width: 1050px) {
  .container, .slide {
    height: 40vh;
  }
  .title {
    font-size: 2.4rem;
  }
}

@media (min-width: 1050px) and (max-width: 1150px) {

}

@media (min-width: 1150px) and (max-width: 1250px) {

  .title {
    font-size: 2.7rem;
  }
}