.button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    font-family: "Assistant";
    background: white;
    justify-content: center;
    display: flex;
    margin:2% auto;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 5px 1px rgba(61, 61, 61, 0.2); /* Classic box shadow with #D61311 color */
    color: #222; /* Matt gray text color */
    transition: all 0.3s ease;
    direction: rtl;
  }
  
  .button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(61, 61, 61, 0.4);
  }
  
  .button:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(61, 61, 61, 0.2);
    background-color: #f8f8f8;
  }