.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px;
}

.image {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 350px;
}

.image:hover {
  transform: translateY(-5px);
}

/* Responsive styles */
@media (max-width: 350px) {
  .container {
      gap: 10px;
      padding: 10px;
  }
  .image {
      width: 100%;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .container {
      gap: 15px;
      padding: 15px;
  }
  .image {
      width: 90%;
  }
}

@media (min-width: 450px) and (max-width: 550px) {
  .image {
      width: 95%;
  }
}

@media (min-width: 550px) and (max-width: 650px) {
  .image {
      width: 250px;
  }
}

@media (min-width: 650px) and (max-width: 750px) {
  .image {
      width: 280px;
  }
}

@media (min-width: 750px) and (max-width: 850px) {
  .image {
      width: 310px;
  }
}

@media (min-width: 850px) and (max-width: 950px) {
  .image {
      width: 330px;
  }
}

@media (min-width: 950px) {
  .image {
      width: 350px;
  }
}

/* Additional media query for larger screens */
@media (min-width: 1250px) {
  .container {
      max-width: 1200px;
      margin: 0 auto;
  }
}