.row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 2% auto;
    flex-wrap: wrap;
}

.title {
    font-size: 3rem;
    margin: 2% auto;
    font-family: "Assistant";
    text-align: center;
}

/* Responsive styles */
@media (max-width: 350px) {
    .row {
        flex-direction: column;
        align-items: center;
    }
    .title {
        font-size: 2rem;
    }
}

@media (min-width: 350px) and (max-width: 450px) {
    .row {
        justify-content: space-evenly;
    }

    .title {
        font-size: 2.2rem;
    }
}

@media (min-width: 450px) and (max-width: 550px) {
    .row {
        justify-content: space-evenly;
    }
  
    .title {
        font-size: 2.4rem;
    }
}

@media (min-width: 550px) and (max-width: 650px) {
    .row {
        justify-content: space-evenly;
    }
    
    .title {
        font-size: 2.6rem;
    }
}

@media (min-width: 650px) and (max-width: 750px) {
    .row {
        justify-content: space-evenly;
    }
    
    .title {
        font-size: 2.7rem;
    }
}

@media (min-width: 750px) and (max-width: 850px) {
    .row {
        justify-content: space-evenly;
    }

    .title {
        font-size: 2.8rem;
    }
}

@media (min-width: 850px) and (max-width: 950px) {
    .row {
        justify-content: space-evenly;
    }
 
    .title {
        font-size: 2.9rem;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .row {
        justify-content: space-evenly;
    }

}

@media (min-width: 1050px) and (max-width: 1150px) {
    .row {
        justify-content: space-evenly;
    }
 
}

@media (min-width: 1150px) and (max-width: 1250px) {
    .row {
        justify-content: space-evenly;
    }
  
}